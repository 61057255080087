var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('div',{staticClass:"p-4"},[_c('trac-back-button',[_c('span',{staticClass:"text-gray-600"},[_vm._v(" Bill Category ")])]),_c('p',{staticClass:"text-xs text-gray-700 mt-2"},[_vm._v(" Choose one of the bills category below. ")]),_c('div',{staticClass:"mt-4 grid grid-cols-3 items-center gap-10"},[_c('div',{staticClass:"flex items-center mt-6 cursor-pointer p-6 bg-white shadow-md rounded-md",on:{"click":function($event){return _vm.$router.push({
            name: 'BillPayment',
            params: {
              type: 'Cable Tv',
            },
          })}}},[_c('svg',{staticClass:"w-10 h-10",attrs:{"viewBox":"0 0 48 48","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"48","height":"48","rx":"4","fill":"#EFFAFF"}}),_c('path',{attrs:{"d":"M30.5 33H17.5C17.22 33 17 32.78 17 32.5C17 32.22 17.22 32 17.5 32H30.5C30.78 32 31 32.22 31 32.5C31 32.78 30.78 33 30.5 33Z","stroke":"#003283","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M20 28V32","stroke":"#003283","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M28 28V32","stroke":"#003283","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M33.5 28H14.5C14.22 28 14 27.78 14 27.5V15.5C14 15.22 14.22 15 14.5 15H33.5C33.78 15 34 15.22 34 15.5V27.5C34 27.78 33.78 28 33.5 28Z","fill":"#EFFAFD","stroke":"#003283","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}})]),_vm._m(0)]),_c('div',{staticClass:"flex items-center mt-6 cursor-pointer p-6 bg-white shadow-md rounded-md",on:{"click":function($event){return _vm.$router.push({
            name: 'BillPayment',
            params: {
              type: 'Electricity',
            },
          })}}},[_c('svg',{staticClass:"w-10 h-10",attrs:{"viewBox":"0 0 48 48","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"48","height":"48","rx":"4","fill":"#EFFAFF"}}),_c('path',{attrs:{"d":"M21.5 29H26.5","stroke":"#003283","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M26.5 32H21.5V33H26.5V32Z","stroke":"#003283","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M22.36 27H25.64C25.64 27 26.88 25.27 27.75 24.01C28.3 23.19 28.7 22.17 28.7 21.2C28.7 17.84 26.59 16 24 16C21.41 16 19.3 17.84 19.3 21.2C19.3 22.17 19.69 23.19 20.25 24.01C20.39 24.2 22.36 27 22.36 27Z","fill":"#EFFAFD","stroke":"#003283","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M24 22.99V27","stroke":"#003283","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M24 23C24.5523 23 25 22.5523 25 22C25 21.4477 24.5523 21 24 21C23.4477 21 23 21.4477 23 22C23 22.5523 23.4477 23 24 23Z","fill":"#B4E6FF","stroke":"#003283","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M33.9999 20H31.4299","stroke":"#003283","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M32.7301 15L30.3301 16.51","stroke":"#003283","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M16.57 20H14","stroke":"#003283","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M17.67 16.51L15.27 15","stroke":"#003283","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('p',{staticClass:"text-xs text-gray-700 ml-5"},[_vm._v("Electricity")])])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"text-xs text-gray-700 ml-5"},[_vm._v("Cable TV")])])
}]

export { render, staticRenderFns }